import React from "react";

const Footer = () => {
	return (
		<footer className="bg-gray-800 text-white p-4 mt-4">
			<p>This website is unofficial and is not associated in any way with the Formula 1 companies.</p>
			<p>F1, FORMULA ONE, FORMULA 1, FIA FORMULA ONE WORLD CHAMPIONSHIP, GRAND PRIX and related marks</p>
			<p>are trade marks of Formula One Licensing B.V.</p>
		</footer>
	);
};

export default Footer;
