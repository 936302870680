import React, { useState, useEffect } from "react";
import Select from "react-select";

function Selector({ setLoading, selectedYear, setSelectedYear, selectedGp, setSelectedGp, selectedSession, setSelectedSession, selectedDrivers, setSelectedDrivers }) {
	const [years] = useState([2024, 2023, 2022, 2021, 2020, 2019, 2018]);
	const [gps, setGps] = useState(["No content"]);
	const [sessions, setSessions] = useState(["No content"]);
	const [drivers, setDrivers] = useState(["No content"]);

	// Dynamic placeholders
	const [gpPlaceholder, setGpPlaceholder] = useState("GP");
	const [sessionPlaceholder, setSessionPlaceholder] = useState("Session");
	const [driverPlaceholder, setDriverPlaceholder] = useState("Drivers");

	useEffect(() => {
		if (selectedYear) {
			setGps(["No content"]);
			setSessions(["No content"]);
			setDrivers(["No content"]);
			setGpPlaceholder("Select GP");
			setSessionPlaceholder("Session");
			setDriverPlaceholder("Drivers");
			setSelectedGp(null);
			setSelectedSession(null);
			setSelectedDrivers([]);

			setLoading(true);
			const url = new URL("https://racingdatachart-backend.onrender.com/circuits");
			url.searchParams.append("year", selectedYear);

			fetch(url, {
				method: "GET",
				credentials: "include",
			})
				.then((response) => response.json())
				.then((data) => {
					setGps(data.length ? data : ["No content"]);
				})
				.catch((error) => {
					console.error("There was an error fetching the races!", error);
					setGps(["No content"]);
				})
				.finally(() => setLoading(false));
		}
	}, [selectedYear, setLoading]);

	useEffect(() => {
		if (selectedGp) {
			setSessions(["No content"]);
			setDrivers(["No content"]);
			setSessionPlaceholder("Select session");
			setDriverPlaceholder("Drivers");
			setSelectedSession(null);
			setSelectedDrivers([]);

			setLoading(true);
			const url = new URL("https://racingdatachart-backend.onrender.com/sessions");
			url.searchParams.append("year", selectedYear);
			url.searchParams.append("circuit", selectedGp);

			fetch(url, {
				method: "GET",
				credentials: "include",
			})
				.then((response) => response.json())
				.then((data) => {
					setSessions(data.length ? data : ["No content"]);
				})
				.catch((error) => {
					console.error("There was an error fetching the sessions!", error);
					setSessions(["No content"]);
				})
				.finally(() => setLoading(false));
		}
	}, [selectedGp, selectedYear, setLoading]);

	useEffect(() => {
		if (selectedSession) {
			setDrivers(["No content"]);
			setDriverPlaceholder("Select Drivers");
			setSelectedDrivers([]);

			setLoading(true);
			const url = new URL("https://racingdatachart-backend.onrender.com/drivers");
			url.searchParams.append("year", selectedYear);
			url.searchParams.append("circuit", selectedGp);
			url.searchParams.append("session", selectedSession);

			fetch(url, {
				method: "GET",
				credentials: "include",
			})
				.then((response) => response.json())
				.then((data) => {
					const options = data.map((driver) => ({
						value: driver.abbreviation,
						label: driver.abbreviation,
						color: driver.color,
					}));
					setDrivers(options.length ? options : ["No content"]);
				})
				.catch((error) => {
					console.error("There was an error fetching the drivers!", error);
					setDrivers(["No content"]);
				})
				.finally(() => setLoading(false));
		}
	}, [selectedSession, selectedGp, selectedYear, setLoading]);

	function createOptions(items) {
		return items.map((item) => ({
			value: item.value || item, // Handles both string and object cases
			label: item.label || item, // Handles both string and object cases
			...(item.color && { color: item.color }), // Conditionally includes the color property
		}));
	};

	return (
		<div className="flex flex-col items-center max-w-[1200px] flex-grow">
			<div className="flex flex-row w-full justify-center">
				<div className="w-1/4 mx-2">
					<Select
						options={createOptions(years)}
						value={years.find((year) => year === selectedYear) ? { value: selectedYear, label: selectedYear } : null}
						onChange={(option) => setSelectedYear(option ? option.value : null)}
						placeholder="Year"
					/>
				</div>
				<div className="w-1/4 mx-2">
					<Select
						options={createOptions(gps)}
						value={gps.find((gp) => gp === selectedGp) ? { value: selectedGp, label: selectedGp } : null}
						onChange={(option) => setSelectedGp(option ? option.value : null)}
						placeholder={gpPlaceholder}
						isDisabled={!selectedYear}
					/>
				</div>
				<div className="w-1/4 mx-2">
					<Select
						options={createOptions(sessions)}
						value={sessions.find((session) => session === selectedSession) ? { value: selectedSession, label: selectedSession } : null}
						onChange={(option) => setSelectedSession(option ? option.value : null)}
						placeholder={sessionPlaceholder}
						isDisabled={!selectedGp}
					/>
				</div>
				<div className="w-1/4 mx-2">
					<Select
						options={drivers} // options now include both label and color
						value={selectedDrivers}
						onChange={(selectedOptions) => setSelectedDrivers(selectedOptions || [])}
						placeholder={driverPlaceholder}
						isMulti
						isDisabled={!selectedSession}
						styles={{
							option: (provided, state) => ({
								...provided,
								color: state.data.color,
							}),
							multiValue: (provided, state) => ({
								...provided,
								backgroundColor: state.data.color,
							}),
							multiValueLabel: (provided, state) => ({
								...provided,
								color: "white",
							}),
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Selector;
