import React, { useState } from "react";
import Selector from "./Selector";
import Loader from "./Loader";
import LapTimesChart from "./LapTimesChart";
import DataRetriver from "./DataRetriver";

function App() {
	const [loading, setLoading] = useState(false);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedGp, setSelectedGp] = useState(null);
	const [selectedSession, setSelectedSession] = useState(null);
	const [selectedDrivers, setSelectedDrivers] = useState([]);
	const [data, setData] = useState(null);

	return (
		<div className="flex flex-col flex-grow justify-center max-w-[1200px]">
			<Selector
				setLoading={setLoading}
				selectedYear={selectedYear}
				setSelectedYear={setSelectedYear}
				selectedGp={selectedGp}
				setSelectedGp={setSelectedGp}
				selectedSession={selectedSession}
				setSelectedSession={setSelectedSession}
				selectedDrivers={selectedDrivers}
				setSelectedDrivers={setSelectedDrivers}
			/>
			<div className="flex flex-row flex-grow justify-center items-center max-w-[1200px]">
				<Loader loading={loading} />
			</div>
			<DataRetriver
				selectedYear={selectedYear}
				selectedGp={selectedGp}
				selectedSession={selectedSession}
				selectedDrivers={selectedDrivers}
				setLoading={setLoading}
				data={data}
				setData={setData}
			/>
			{selectedDrivers.length > 0 && (
				<LapTimesChart
					data={data}
				/>
			)}
		</div>
	);
};

export default App;
