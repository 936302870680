import React from "react";

const Header = () => {
	return (
		<header className="bg-gray-800 text-white p-4">
			<h1 className="text-3xl font-bold">RacingDataChart</h1>
			<h3 className="text-xl font-bold">Everything about telemetry on your FORUMAL ONE race weekend</h3>
		</header>
	);
};

export default Header;