import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from "recharts";

import soft from "./img/soft.png";
import medium from "./img/medium.png";
import hard from "./img/hard.png";
import inter from "./img/inter.png";
import wet from "./img/wet.png";

const compoundImages = {
	SOFT: soft,
	MEDIUM: medium,
	HARD: hard,
	INTERMEDIATE: inter,
	WET: wet,
};

const compoundColors = {
	SOFT: "#da291c",
	MEDIUM: "#ffd12e",
	HARD: "#f0f0ec",
	INTERMEDIATE: "#43b02a",
	WET: "#0067ad",
	"TEST-UNKNOWN": "#434649",
	UNKNOWN: "#00ffff",
	UNDEF: "#000000",
};

function formatDuration(seconds) {
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = Math.floor(seconds % 60);
	const milliseconds = Math.floor((seconds % 1) * 1000);

	return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}.${milliseconds.toString().padStart(3, "0")}`;
}

function formatDurationLabel(seconds) {
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = Math.floor(seconds % 60);

	return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
}

function findMinMax(chartDataLaps) {
	let minValue = Number.POSITIVE_INFINITY;
	let maxValue = Number.NEGATIVE_INFINITY;

	chartDataLaps.forEach((driverData) => {
		driverData.data.forEach((lap) => {
			if (lap.lapTime < minValue) minValue = lap.lapTime;
			if (lap.lapTime > maxValue) maxValue = lap.lapTime;
		});
	});

	return { min: minValue, max: maxValue };
}

function findMinMaxLapDomain(chartDataLaps) {
	let minValue = Number.POSITIVE_INFINITY;
	let maxValue = Number.NEGATIVE_INFINITY;

	chartDataLaps.forEach((driverData) => {
		driverData.data.forEach((lap) => {
			if (lap.LapNumber < minValue) minValue = lap.LapNumber;
			if (lap.LapNumber > maxValue) maxValue = lap.LapNumber;
		});
	});

	return { min: minValue, max: maxValue };
}

function findMinMaxTimeDomain(chartDataLaps) {
	let minValue = Number.POSITIVE_INFINITY;
	let maxValue = Number.NEGATIVE_INFINITY;

	chartDataLaps.forEach((driverData) => {
		driverData.data.forEach((lap) => {
			if (lap.LapStartDate < minValue) minValue = lap.LapStartDate;
			if (lap.LapStartDate > maxValue) maxValue = lap.LapStartDate;
		});
	});

	return { min: minValue, max: maxValue };
}

function formatLapLabel(lapNumber) {
	return lapNumber === 1 || lapNumber % 5 === 0 ? lapNumber : "";
}

function formatTimeLabel(timeSeconds) {
	let hours = Math.floor(timeSeconds / 3600);
	const minutes = Math.floor((timeSeconds % 3600) / 60);

	// Determine AM or PM and adjust hours accordingly
	const period = hours >= 12 ? "PM" : "AM";
	hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 is shown instead of 0

	// Ensure two digits for both hours and minutes
	const formattedHours = hours.toString().padStart(2, "0");
	const formattedMinutes = minutes.toString().padStart(2, "0");

	return `${formattedHours}:${formattedMinutes} ${period}`;
}

/////////////////////////////////////////////////////////////////////

function LapTimesChart({ data }) {
	const [showLapTimes, setShowLapTimes] = useState(true);
	const [showInvalidLaps, setShowInvalidLaps] = useState(true);
	const [showPitLaps, setShowPitLaps] = useState(true);
	const [showFirstLap, setShowFirstLap] = useState(true);
	const [showQuickLap, setShowQuickLap] = useState(true);

	const [chartDataLaps, setChartDataLaps] = useState([]);
	const [chartDataTimes, setChartDataTimes] = useState([]);

	function toggleShowLapTimes() {
		if (chartDataTimes && chartDataLaps) {
			setShowLapTimes(!showLapTimes);
		}
	}

	function toggleShowQuickLaps() {
		setShowQuickLap(!showQuickLap);
	}

	function toggleShowInvalidLaps() {
		setShowInvalidLaps(!showInvalidLaps);
	}

	function toggleShowPitLaps() {
		setShowPitLaps(!showPitLaps);
	}

	function toggleShowFirstLap() {
		setShowFirstLap(!showFirstLap);
	}

	useEffect(() => {
		if (data) {
			const lapsData = Object.entries(data).map(([driverCode, driverData]) => {
				return {
					driver: driverCode.toUpperCase(),
					data: driverData.LapNumber.map((lapNumber, index) => {
						const lapTime = driverData.LapTime[index];
						if (lapTime !== null) {
							return {
								lapNumber,
								lapTime,
							};
						}
						return {
							lapNumber,
							lapTime: 0,
						};
					}).filter((entry) => entry !== null),
				};
			});
			if (!showFirstLap) {
				lapsData.forEach((driverData) => {
					driverData.data.shift(); // Remove the first lap for all drivers
				});
			}
			if (!showInvalidLaps) {
				lapsData.forEach((driverData) => {
					driverData.data = driverData.data.filter((lapData) => lapData.lapTime !== 0); // Remove laps with 0 lap time
				});
			}
			if (!showQuickLap) {
				lapsData.forEach((driverData) => {
					// Filter out laps with lapTime === 0 and find the minimum valid lap time
					const validLapTimes = driverData.data.map((lapData) => lapData.lapTime).filter((lapTime) => lapTime > 0); // Exclude lapTime === 0

					// If there are valid laps, find the minimum
					if (validLapTimes.length > 0) {
						const minLapTime = Math.min(...validLapTimes);

						// Filter out laps that are more than 110% of the minimum valid lap time
						driverData.data = driverData.data.filter(
							(lapData) => lapData.lapTime > 0 && lapData.lapTime <= minLapTime * 1.1 // Exclude laps with lapTime === 0 and non-quick laps
						);
					} else {
						// If all lap times are 0, clear the laps for that driver
						driverData.data = [];
					}
				});
			}
			if (!showPitLaps) {
				lapsData.forEach((driverData) => {
					// Filter the laps based on whether PitOutTime or PitInTime is not 'None'
					driverData.data = driverData.data.filter((lap, index) => {
						// Check if the PitOutTime or PitInTime is not 'None'
						return data[driverData.driver]?.PitOutTime[index] === null && data[driverData.driver]?.PitInTime[index] === null;
					});
				});
			}
			lapsData.sort((a, b) => b.data.length - a.data.length);
			setChartDataLaps(lapsData);

			const timesData = Object.entries(data).map(([driverCode, driverData]) => {
				return {
					driver: driverCode.toUpperCase(),
					data: driverData.LapStartDate.map((LapStartDate, index) => {
						const lapStartDate = LapStartDate;
						const lapTime = driverData.LapTime[index];
						if (lapTime !== null) {
							return {
								lapStartDate,
								lapTime,
							};
						}
						return {
							lapStartDate,
							lapTime: 0,
						};
					}).filter((entry) => entry !== null),
				};
			});
			if (!showFirstLap) {
				timesData.forEach((driverData) => {
					driverData.data.shift(); // Remove the first lap for all drivers
				});
			}
			if (!showInvalidLaps) {
				timesData.forEach((driverData) => {
					driverData.data = driverData.data.filter((lapData) => lapData.lapTime !== 0); // Remove laps with 0 lap time
				});
			}
			if (!showQuickLap) {
				timesData.forEach((driverData) => {
					// Filter out laps with lapTime === 0 and find the minimum valid lap time
					const validLapTimes = driverData.data.map((lapData) => lapData.lapTime).filter((lapTime) => lapTime > 0); // Exclude lapTime === 0

					// If there are valid laps, find the minimum
					if (validLapTimes.length > 0) {
						const minLapTime = Math.min(...validLapTimes);

						// Filter out laps that are more than 110% of the minimum valid lap time
						driverData.data = driverData.data.filter(
							(lapData) => lapData.lapTime > 0 && lapData.lapTime <= minLapTime * 1.1 // Exclude laps with lapTime === 0 and non-quick laps
						);
					} else {
						// If all lap times are 0, clear the laps for that driver
						driverData.data = [];
					}
				});
			}
			if (!showPitLaps) {
				timesData.forEach((driverData) => {
					// Filter the laps based on whether PitOutTime or PitInTime is not 'None'
					driverData.data = driverData.data.filter((lap, index) => {
						// Check if the PitOutTime or PitInTime is not 'None'
						return data[driverData.driver]?.PitOutTime[index] === null && data[driverData.driver]?.PitInTime[index] === null;
					});
				});
			}
			timesData.sort((a, b) => b.data.length - a.data.length);
			setChartDataTimes(timesData);
		}
	}, [data, showInvalidLaps, showPitLaps, showFirstLap, showQuickLap]);

	if (!data) {
		return null;
	} else if(data.length === 0) {
		return null;
	}
	
	const { minLapDomain, maxLapDomain } = findMinMaxLapDomain(chartDataLaps);
	const { minTimeDomain, maxTimeDomain } = findMinMaxTimeDomain(chartDataTimes);

	const { min, max } = findMinMax(chartDataLaps);
	const buffer = (max - min) * 0.1;

	function renderDot(dotProps, index, driver, radius) {
		const { cx, cy } = dotProps;

		// Find the corresponding driver data for the current driver
		const driverData = chartDataLaps.find((driverEntry) => driverEntry.driver === driver);

		// Ensure that the lap data exists for this specific driver and index
		if (driverData && driverData.data[index] && driverData.data[index].lapTime !== null) {
			const lapNumber = driverData.data[index]?.lapNumber;
			const compound = data[driver]?.Compound?.[lapNumber - 1] || "UNDEF";

			// Render the dot with the correct compound color and size
			return (
				<circle
					cx={cx}
					cy={cy}
					r={radius} // Use the radius passed in
					fill={compoundColors[compound]}
					stroke={dotProps.stroke}
					strokeWidth={2}
				/>
			);
		}

		// If there's no valid lap data for this index, don't render anything
		return null;
	}

	function renderTooltipLaps({ active, payload, label }) {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip bg-black bg-opacity-75 p-3 rounded-lg text-white">
					<div className="label">{`LAP ${label}`}</div>
					<div className="desc flex flex-col">
						{payload.map((entry, index) => {
							const driver = entry.name;
							const value = entry.value;
							const driverData = chartDataLaps.find((driverEntry) => driverEntry.driver === driver);

							// Find the correct index for the current driver and lap number
							const indexCompound = driverData?.data.findIndex((item) => item.lapNumber === label);
							const compound = data[driver]?.Compound?.[indexCompound];
							const displayValue = value === 0 ? "N/A" : `${formatDuration(value)}`;
							const compoundImage = compoundImages[compound];

							return compound != null ? (
								<div key={`tooltip-item-${index}`} className="flex flex-row items-center">
									<span className="mr-2">
										{driver}: {displayValue}
									</span>
									<img src={compoundImage} alt={compound} className="w-8 h-8" />
								</div>
							) : null;
						})}
					</div>
				</div>
			);
		}

		return null;
	}


	function renderTooltipTimes({ active, payload, label }) {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip bg-black bg-opacity-75 p-3 rounded-lg text-white">
					<div className="label">{`Time ${formatTimeLabel(label)}`}</div>
					<div className="desc flex flex-col">
						{payload.map((entry, index) => {
							const driver = entry.name;
							const value = entry.value;

							// Find the correct driver data in chartDataTimes
							const driverData = chartDataTimes.find((driverEntry) => driverEntry.driver === driver);

							// Find the correct index for the current driver and time label
							const indexCompound = driverData?.data.findIndex((item) => item.lapStartDate === label);
							const compound = data[driver]?.Compound?.[indexCompound];
							const displayValue = value === 0 ? "N/A" : `${formatDuration(value)}`;
							const compoundImage = compoundImages[compound];

							return compound != null ? (
								<div key={`tooltip-item-${index}`} className="flex flex-row items-center">
									<span className="mr-2">
										{driver}: {displayValue}
									</span>
									<img src={compoundImage} alt={compound} className="w-8 h-8" />
								</div>
							) : null;
						})}
					</div>
				</div>
			);
		}

		return null;
	}


	return (
		<div>
			{showLapTimes ? (
				<ResponsiveContainer width="100%" height={400}>
					<LineChart>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey={"lapNumber"}
							label={{ value: "Lap Number", position: "insideBottom", offset: 0 }}
							tickFormatter={formatLapLabel}
							type={"number"}
							domain={[minLapDomain, maxLapDomain]}
							allowDuplicatedCategory={false}
						/>
						<YAxis label={{ value: "Lap Time", angle: -90, position: "insideLeft" }} tickFormatter={formatDurationLabel} domain={[min - buffer < 0 ? 0 : min - buffer, max + buffer]} />
						<Tooltip content={renderTooltipLaps} />
						<Legend />
						{chartDataLaps.map((dataLaps) => (
							<Line
								key={dataLaps.driver}
								type="monotone"
								dataKey="lapTime"
								data={dataLaps.data}
								name={dataLaps.driver}
								stroke={data[dataLaps.driver]?.driverColor || "#000"}
								dot={(dotProps) => renderDot(dotProps, dotProps.index, dataLaps.driver, 4)}
								activeDot={(dotProps) => renderDot(dotProps, dotProps.index, dataLaps.driver, 8)}
							/>
						))}
					</LineChart>
				</ResponsiveContainer>
			) : (
				<ResponsiveContainer width="100%" height={400}>
					<LineChart>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey={"lapStartDate"}
							label={{ value: "Time", position: "insideBottom", offset: 0 }}
							tickFormatter={formatTimeLabel}
							type={"number"}
							domain={[minTimeDomain, maxTimeDomain]}
							allowDuplicatedCategory={false}
						/>
						<YAxis label={{ value: "Lap Time", angle: -90, position: "insideLeft" }} tickFormatter={formatDurationLabel} domain={[min - buffer < 0 ? 0 : min - buffer, max + buffer]} />
						<Tooltip content={renderTooltipTimes} />
						<Legend />
						{chartDataTimes.map((dataTimes) => (
							<Line
								key={dataTimes.driver}
								type="monotone"
								dataKey="lapTime"
								data={dataTimes.data}
								name={dataTimes.driver}
								stroke={data[dataTimes.driver]?.driverColor || "#000"}
								dot={(dotProps) => renderDot(dotProps, dotProps.index, dataTimes.driver, 4)}
								activeDot={(dotProps) => renderDot(dotProps, dotProps.index, dataTimes.driver, 8)}
							/>
						))}
					</LineChart>
				</ResponsiveContainer>
			)}
			<div className="flex flex-row justify-center">
				<button className="btn w-1/8 mx-2" onClick={toggleShowLapTimes}>
					Switch to {showLapTimes ? "Time" : "Lap Numbers"}
				</button>
				{/* <button className="btn w-1/8 mx-2" onClick={() => {/* Implement Fastest Lap Logic 
					Pick Fastest Lap
				</button> */}
				<button className="btn w-1/8 mx-2" onClick={toggleShowQuickLaps}>
					{showQuickLap ? "Show only quick" : "Show all"} laps
				</button>
				<button className="btn w-1/8 mx-2" onClick={toggleShowInvalidLaps}>
					{showInvalidLaps ? "Hide" : "Show"} invalid laps
				</button>
				<button className="btn w-1/8 mx-2" onClick={toggleShowPitLaps}>
					{showPitLaps ? "Hide" : "Show"} pit In/Out laps
				</button>
				<button className="btn w-1/8 mx-2" onClick={toggleShowFirstLap}>
					{showFirstLap ? "Hide" : "Show"} first lap
				</button>
			</div>
		</div>
	);
};

export default LapTimesChart;
