import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MainPage from "./components/MainPage";

function App() {
    return (
        <div className="min-h-screen bg-gray-100">
            <Header />
            <main className="flex justify-center items-center p-4">
                <MainPage />
            </main>
            <Footer />
        </div>
    );
}

export default App;
