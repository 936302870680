import React from "react";

function Loader({ loading }) {
	return (
		<div className="mt-4" style={{ minHeight: "40px" }}>
			{loading && (
				<div className="flex flex-row items-center">
					<span>Loading</span>
					<span className="loading loading-infinity loading-lg"></span>
				</div>
			)}
		</div>
	);
};

export default Loader;
