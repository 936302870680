import { useEffect } from "react";

function DataRetriver({ selectedYear, selectedGp, selectedSession, selectedDrivers, setLoading, data, setData }) {
	useEffect(() => {
		if (selectedDrivers.length > 0) {
			setLoading(true);
			const url = new URL("https://racingdatachart-backend.onrender.com/callData");
			url.searchParams.append("year", selectedYear);
			url.searchParams.append("circuit", selectedGp);
			url.searchParams.append("session", selectedSession);
			url.searchParams.append("drivers", selectedDrivers.map((driver) => driver.value).join(","));

			fetch(url, {
				method: "GET",
				credentials: "include",
			})
				.then((response) => response.json())
				.then((data) => {
					setData(data);
				})
				.catch((error) => {
					console.error("There was an error fetching the data!", error);
				})
				.finally(() => setLoading(false));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDrivers]);

	return null;
};

export default DataRetriver;
